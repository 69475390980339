import { Move } from "@/ts/royalur/model/Move";
import { Random } from "@/ts/util/Random";
import { Game } from "@/ts/royalur/Game";
import { Agent } from "@/ts/royalur/agent/Agent";
import { LikelihoodAgent } from "@/ts/royalur/agent/LikelihoodAgent";
import { PiecesAdvancedUtilityFn } from "@/ts/royalur/agent/utility/PiecesAdvancedUtilityFn";
import { SimpleRuleSet } from "@/ts/royalur/rules/simple/SimpleRuleSet";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";


/**
 * The strategy of the easy agent on RoyalUr.net. It intentionally
 * plays badly, but hopefully in a way that is still fun to beat.
 */
export class MediumAgent extends Agent {

    public static readonly LIKELIHOOD_THRESHOLD = 0.05;

    private static readonly RANDOM_AMOUNT = 2.0;

    private readonly delegate: LikelihoodAgent;
    private readonly random: Random;

    constructor(rules: RuleSet, random?: Random) {
        super();

        if (!(rules instanceof SimpleRuleSet))
            throw new Error("Only standard rule sets are supported for the medium agent");

        const utilityFn = new PiecesAdvancedUtilityFn(rules);
        this.delegate = new LikelihoodAgent(rules, utilityFn, MediumAgent.LIKELIHOOD_THRESHOLD);
        this.random = (random ? random : new Random());
    }

    private addRandom(): number {
        return this.random.nextFloat(MediumAgent.RANDOM_AMOUNT);
    }

    orderMoves(moves: Move[]) {
        // Less advanced pieces have lower path indices.
        const computePathIndex = (move: Move) => {
            return move.isIntroduction() ? -1 : move.getSourcePiece().getPathIndex();
        };

        // Sort so that least advanced pieces come first.
        moves.sort((a: Move, b: Move) => {
            return computePathIndex(a) - computePathIndex(b);
        });
    }

    override decideMove(game: Game, moves: Move[]): Move {
        this.orderMoves(moves);
        const moveUtilities = this.delegate.scoreMoves(game, moves);

        let bestMove: Move = moves[0];
        let bestMoveUtility: number = moveUtilities[0] + this.addRandom();

        for (let index = 1; index < moves.length; ++index) {
            const move = moves[index];
            const realUtility = moveUtilities[index];
            const effectiveUtility = realUtility + index + this.addRandom();
            if (effectiveUtility > bestMoveUtility) {
                bestMove = move;
                bestMoveUtility = effectiveUtility;
            }
        }
        return bestMove;
    }
}
