import { Move } from "@/ts/royalur/model/Move";
import { Random } from "@/ts/util/Random";
import { Game } from "@/ts/royalur/Game";
import { Agent } from "@/ts/royalur/agent/Agent";


/**
 * The strategy of the easy agent on RoyalUr.net. It intentionally
 * plays badly, but hopefully in a way that is still fun to beat.
 */
export class EasyAgent extends Agent {
    private static readonly RANDOM_ORDER_CHANCE = 0.3;
    private static readonly SCORE_CHANCE = 0.9;
    private static readonly CAPTURE_CHANCE = 0.25;
    private static readonly ROSETTE_CHANCE = 0.25;
    private static readonly MOVE_OFF_ROSETTE_CHANCE = 0.75;

    private readonly random: Random;

    constructor(random?: Random) {
        super();
        this.random = (random ? random : new Random());
    }

    orderMoves(moves: Move[]) {
        // Sometimes, order moves randomly
        // (this is a better strategy, believe it or not).
        if (this.random.nextFloat() < EasyAgent.RANDOM_ORDER_CHANCE) {
            this.random.shuffle(moves);
            return;
        }

        // Less advanced pieces have lower path indices.
        const computePathIndex = (move: Move) => {
            return move.isIntroduction() ? -1 : move.getSourcePiece().getPathIndex();
        };

        // Sort so that least advanced pieces come first.
        moves.sort((a: Move, b: Move) => {
            return computePathIndex(a) - computePathIndex(b);
        });
    }

    override decideMove(game: Game, availableMoves: Move[]): Move {
        if (availableMoves.length === 0)
            throw new Error("No moves available");
        if (availableMoves.length === 1)
            return availableMoves[0];

        // Prioritise moves.
        availableMoves = [...availableMoves];
        this.orderMoves(availableMoves);

        // If they can score a piece, score it.
        if (this.random.nextFloat() < EasyAgent.SCORE_CHANCE) {
            for (const move of availableMoves) {
                if (move.isScore())
                    return move;
            }
        }

        // If there is a capture, make it.
        if (this.random.nextFloat() < EasyAgent.CAPTURE_CHANCE) {
            for (const move of availableMoves) {
                if (move.isCapture())
                    return move;
            }
        }

        // If there is a rosette to land on, land on it.
        if (this.random.nextFloat() < EasyAgent.ROSETTE_CHANCE) {
            const shape = game.getBoard().getShape();
            for (const move of availableMoves) {
                if (move.isLandingOnRosette(shape))
                    return move;
            }
        }

        // If there is a rosette to move off of, move off it.
        if (this.random.nextFloat() < EasyAgent.MOVE_OFF_ROSETTE_CHANCE) {
            const shape = game.getBoard().getShape();
            for (const move of availableMoves) {
                if (!move.isIntroduction() && shape.isRosette(move.getSource()))
                    return move;
            }
        }

        // Make the first move according to the ordering.
        return availableMoves[0];
    }
}
