

/**
 * Generates random numbers.
 */
export class Random {
    /**
     * Generate a floating point number in the range [0, limit).
     * If no limit is provided, then a number is generated in the
     * range [0, 1).
     */
    nextFloat(limit?: number): number {
        if (limit === undefined) {
            limit = 1.0;
        }
        return Math.random() * limit;
    }

    /**
     * Generates an integer in the range [0, limit).
     */
    nextInt(limit: number): number {
        return Math.floor(this.nextFloat(limit));
    }

    /**
     * Shuffles the given array.
     */
    shuffle<T>(array: T[]): void {
        let currentIndex = array.length;
        while (currentIndex > 0) {
            const randomIndex = this.nextInt(currentIndex);
            currentIndex -= 1;

            const temp = array[randomIndex];
            array[randomIndex] = array[currentIndex];
            array[currentIndex] = temp;
        }
    }
}
